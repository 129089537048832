<template>
  <Subheader v-if="loggedIn" :stoStatus="activeSto" type="blso" />
  <div class="sto-banner">
    <div class="container d-flex align-items-center h-100">
      <div v-if="activeSto?.display_status" class="row h-100">
        <div v-if="!countDown?.days?.toString().length">
          <div class="loader"></div>
        </div>
        <div
          v-else
          class="
            col-12 col-lg-6
            h-100
            d-flex
            justify-content-center
            flex-column
          "
        >
          <h2 class="mb-5 mb-sm-4 mt-5">Blockchain Listed Shares Offering</h2>
          <div class="hide-mobile mb-3">
            <p class="sto-banner-text">
              Take part in the first-ever blockchain listed shares offering of a
              German regulated bank that is in the process of offering
              innovative blockchain oriented solutions to its customers and the
              public.
            </p>
            <p class="sto-banner-text">
              As a fully licensed bank that is dealing with the fast-paced
              changes in the regulatory and compliance environment, we offer the
              banking infrastructure for accounts, loans, savings, payments,
              tokenization, and more...
            </p>
            <p class="sto-banner-text">
              We are bridging the gap between traditional banking and blockchain
              technology.
            </p>
          </div>
          <p class="sto-status-text">
            <span v-if="activeSto?.status == 1"
              >Get ready! BLSO starting soon:</span
            >
            <span v-else-if="activeSto.status == 2"
              >BLSO in progress. Time remaining:</span
            >
            <span v-else-if="activeSto.status == 3">BLSO has expired.</span>
            <span v-else>BLSO has successfully ended.</span>
          </p>
          <div
            v-if="stoStatus && countDown.days != undefined"
            class="countdown-wrapper"
          >
            <div class="countdown-unit-wrapper countdown-days">
              <div class="countdown-num-wrapper">
                <span class="digit">{{ twoDigits(countDown.days) }}</span>
              </div>
              <p class="mt-3">Days</p>
            </div>
            <span class="countdown-divider">:</span>
            <div class="countdown-unit-wrapper countdown-hours">
              <div class="countdown-num-wrapper">
                <span class="digit">{{ twoDigits(countDown.hours) }}</span>
              </div>
              <p class="mt-3">Hours</p>
            </div>
            <span class="countdown-divider">:</span>
            <div class="countdown-unit-wrapper countdown-minutes">
              <div class="countdown-num-wrapper">
                <span class="digit">{{ twoDigits(countDown.minutes) }}</span>
              </div>
              <p class="mt-3">Minutes</p>
            </div>
            <span class="countdown-divider">:</span>
            <div class="countdown-unit-wrapper countdown-seconds">
              <div class="countdown-num-wrapper">
                <span class="digit">{{
                  twoDigits(countDown.seconds, "seconds")
                }}</span>
              </div>
              <p class="mt-3">Seconds</p>
            </div>
          </div>
          <div
            v-if="activeSto.display_status === 'Active'"
            class="progress-wrapper"
          >
            <div
              :style="`width: ${
                (activeSto.current_shares / activeSto.number_of_shares) * 100
              }%`"
              class="progress-bar"
            ></div>
          </div>
          <div
            v-if="activeSto.display_status === 'Active'"
            class="d-flex sto-progress-info justify-content-between"
          >
            <span
              >{{ Math.floor(Number(activeSto.current_shares)) }} Shares</span
            >
            <span
              >{{
                (
                  (Number(activeSto.current_shares) /
                    Number(activeSto.number_of_shares)) *
                  100
                ).toFixed(2)
              }}%</span
            >
            <span
              >{{ Math.floor(Number(activeSto.number_of_shares)) }} Shares</span
            >
          </div>
          <div class="d-flex banner-buttons">
            <router-link to="/investments" class="button me-2">{{
              Number(activeSto.display_current_volume) >=
              Number(activeSto.display_volume)
                ? "View Investment"
                : "Invest Now"
            }}</router-link>
            <button
              @click="showInvestInfoModal = true"
              class="button white me-2"
              to="/"
            >
              How to Invest?
            </button>
            <a
              href="https://wegadmin.omniapps.info/documents/ten31-blso-flyer.pdf"
              target="_blank"
              class="button white"
              >Flyer</a
            >
          </div>
        </div>
      </div>
      <div
        class="h-100 d-flex w-100 align-items-center justify-content-center"
        v-else-if="noSto"
      >
        <h2 class="text-center py-5">There is currently no active STO</h2>
      </div>
    </div>
  </div>
  <div v-if="loading">
    <div class="loader"></div>
  </div>
  <template v-if="!noSto">
    <div class="container">
      <div v-if="activeSto.display_status" class="row gx-4 sto-cards-row">
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>BLSO Status</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="statusIcon" alt="" />
              <span class="sto-status-text"
                >{{
                  parseInt(activeSto.status) === 2
                    ? "In progress"
                    : parseInt(activeSto.status) >= 4 &&
                      9 > parseInt(activeSto.status)
                    ? "Finished"
                    : activeSto.display_status
                }}
                <small v-if="activeSto.display_status === 'Active'"
                  >{{
                    (
                      (Number(activeSto.current_shares) /
                        Number(activeSto.number_of_shares)) *
                      100
                    ).toFixed(2)
                  }}%</small
                ></span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>Max Cap</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="plusIcon" alt="" />
              <span class="sto-status-text"
                >{{ activeSto.number_of_shares }} <small>SHARES</small>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>Accepted Currencies</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="paymentIcon" alt="" />
              <span class="sto-status-text">Euro & Bitcoin</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>Price per share</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="eurIcon" alt="" />
              <span class="sto-status-text"
                >{{ (activeSto.token_price / 100).toFixed(2) }}
                <small>EUR</small>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>Start date (CET)</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="startIcon" alt="" />
              <span class="sto-status-text"
                >{{ activeSto.start_date.slice(0, 10) }}
                <small>{{ activeSto.start_date.slice(11, 19) }}</small></span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="sto-card">
            <h3>End date (CET)</h3>
            <div class="sto-card-info d-flex">
              <img class="sto-card-icon me-3" :src="endIcon" alt="" />
              <span class="sto-status-text"
                >{{ activeSto.end_date.slice(0, 10) }}
                <small>{{ activeSto.end_date.slice(11, 19) }}</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div
    @click="closeModal"
    :class="showInvestInfoModal ? 'active' : ''"
    class="modal-wrapper"
  >
    <div
      @click.stop
      :class="showInvestInfoModal && 'active'"
      class="modal position-relative"
    >
      <div @click="closeModal" class="close-modal">✕</div>
      <div class="modal-content">
        <h4 class="text-center mb-4 mt-3">How to invest in 4 steps</h4>
        <div class="invest-steps">
          <div class="step-item d-flex flex-nowrap align-items-start">
            <img :src="oneIcon" alt="" />
            <p class="ms-3 pt-1 mb-0">
              Register / create an account for the BLSO.
            </p>
          </div>
          <div class="step-item d-flex flex-nowrap align-items-start">
            <img :src="twoIcon" alt="" />
            <p class="ms-3 pt-1 mb-0">
              Deposit a minimum 100,- worth in EUR or Bitcoin into your account.
            </p>
          </div>
          <div class="step-item d-flex flex-nowrap align-items-start">
            <img :src="threeIcon" alt="" />
            <p class="ms-3 pt-1 mb-0">
              Conduct the Video identification KYC, requires an ID Document! The
              costs for KYC is 10,- EUR and will be deducted from your account.
            </p>
          </div>
          <div class="step-item d-flex flex-nowrap align-items-start">
            <img :src="fourIcon" alt="" />
            <p class="ms-3 pt-1 mb-0">
              Once KYC is approved, all BTC is exchanged to EUR at the rate on
              time of approval.
            </p>
          </div>
        </div>
        <div class="disclaimer-box">
          <h5 class="disclaimer-title">DISCLAIMER</h5>
          <p>
            The minimum amount of 2.500.000,- EUR has to be reached before the
            end date, if the minimum amount is not reached then all deposited
            funds will be send back to the indicated SEPA EURO IBAN address of
            your BLSO account.
          </p>
        </div>
        <button type="button" class="button mt-3 w-100" @click="closeModal">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import config from "../../config";
import statusIcon from '../../assets/images/icon-status.svg';
import plusIcon from '../../assets/images/icon-share.svg';
import startIcon from '../../assets/images/icon-start-date-2.svg'
import endIcon from '../../assets/images/icon-end-date.svg';
import eurIcon from '../../assets/images/icon-euro.svg';
import paymentIcon from '../../assets/images/icon-payment-option.svg';
import Subheader from '../../components/Subheader.vue';
import oneIcon from '../../assets/images/num-1.svg';
import twoIcon from '../../assets/images/num-2.svg';
import threeIcon from '../../assets/images/num-3.svg';
import fourIcon from '../../assets/images/num-4.svg';

export default defineComponent({
  name: "STO",
  components: {
    Subheader,
  },
  data() {
    return {
      stoStatuses: {
        1: 'Pending',
        2: 'Active',
        3: 'Expired',
        4: 'Processing payout',
        5: 'Contract deployment',
        6: 'Adding identities',
        7: 'Resolving identities',
        8: 'Minting',
        9: 'Finished'
      },
      loading: false,
      showInvestInfoModal: false,
      accessToken: "",
      activeSto: {},
      countDown: {},
      stoStatus: null,
      noSto: false,
      statusIcon: statusIcon,
      plusIcon: plusIcon,
      startIcon: startIcon,
      endIcon: endIcon,
      eurIcon: eurIcon,
      paymentIcon: paymentIcon,
      loggedIn: false,
      loadingSto: true,
      oneIcon: oneIcon,
      twoIcon: twoIcon,
      threeIcon: threeIcon,
      fourIcon: fourIcon,
      countdownInterval: null,
    };
  },
  mounted() {
    this.$store.watch((state) => {
      this.accessToken = state.accessToken;
      this.loggedIn = state.loggedIn;
      this.loading = true;
    });
    this.fetchSto();
  },
  methods: {
    fetchSto() {
      fetch(`${config.API}/api/v1/last-sto`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.json())
      .then((data) => {
        this.loading = false;
        if (!data.data.sto) {
          this.noSto = true;
          return;
        }
        this.activeSto = data.data.sto;
        this.stoStatus = data.data.sto.display_status;
        this.loadingSto = true;
        if (data.data.sto.status === 1) {
          this.countdown(data.data.sto.start_date);
        } else {
          this.countdown(data.data.sto.end_date);
        }
      })
      .catch((error) => {
        console.log(error);
        this.loadingSto = true;
      });
    },
    closeModal() {
      this.showInvestInfoModal = false;
    },
    countdown(date) {
      const thisWrapper = {
        thisProxy: this
      }
      this.countdownInterval = setInterval(function tick() {
        const endDate = new Date(date); // new Date('2022-01-12T09:27:55');
        const currentTime = new Date();
        let remainingTime = endDate.getTime() - currentTime.getTime();
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        thisWrapper.thisProxy.countDown = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
        return tick;
      } (), 1000);
    },
    twoDigits(num, units) {
      if (units === 'seconds' && !this.countDown.days && !this.countDown.hours && !this.countDown.minutes && !this.countDown.seconds) {
        setTimeout(() => {
          this.fetchSto();
          clearInterval(this.countdownInterval);
        }, 1500);
      }
      if (num <= 0 || this.activeSto.status > 2) {
        return '00';
      } else {
        return num?.toString().length > 1 ? num : `0${num}`;
      }
    },
  },
  watch: {
    showInvestInfoModal: function(val) {
      if (val) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'auto';
      }
    }
  },
});
</script>
<template>
    <div @click="goToRoute" :class="showComponent === true ? 'active' : (($props.type === 'blso' || $props.type === 'investments') && (showComponent === false) ? 'status' : '')" class="subheader align-items-center justify-content-center">
        <template v-if="showComponent === true">
            <img class="me-2" :src="warningIcon" alt="">
            <p v-if="!hasDeposited">You need to deposit initial amount of 100 EUR to be able to start KYC</p>
            <p v-else-if="hasDeposited && !hasCompletedKyc">You need to complete KYC to be able to invest in BLSO</p>
        </template>
        <template v-else-if="($props.type == 'blso' || $props.type === 'investments') && showComponent === false">
            <p>BLSO status: {{4 > Number($props.stoStatus?.status) ? stoStatuses[$props.stoStatus?.status] : 'Finished'}} <span v-if="$props.stoStatus?.status >= 4 && $props.stoStatus?.status < 9">({{stoStatuses[$props.stoStatus?.status]}})</span></p>
        </template>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import warningIcon from '../assets/images/icon-warning.svg';

export default defineComponent({
    name: 'Subheader',
    props: ['type', 'stoStatus'],
    data: function() {
        return {
            warningIcon: warningIcon,
            hasCompletedKyc: true,
            hasDeposited: true,
            showComponent: undefined,
            stoStatuses: {
                1: 'Pending',
                2: 'Active',
                3: 'Expired',
                4: 'Processing payout',
                5: 'Contract deployment',
                6: 'Adding identities',
                7: 'Resolving identities',
                8: 'Minting',
                9: 'Finished'
            }
        }
    },
    mounted() {
        console.log('haha', this.$props.stoStatus);
        this.hasDeposits = this.$store.state.hasMinDeposit;
        this.hasCompletedKyc = !!this.$store.state.userData?.completed_kyc;
        console.log(this.$store.state.hasMinDeposit, !!this.$store.state.userData?.completed_kyc)
        this.$store.watch(state => {
            this.hasDeposited = state.hasMinDeposit;
            this.hasCompletedKyc = !!state.userData?.completed_kyc;
            if ((!state.hasMinDeposit || state.userData?.completed_kyc == 0) && state.loggedIn) {
                this.showComponent = true;
            } else {
                this.showComponent = false;
            }
        });
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'setHasMinDeposit' || mutation.type === 'setKycStatus') {
                if ((!state.hasMinDeposit || state.userData?.completed_kyc == 0) && state.loggedIn) {
                    this.showComponent = true;
                } else {
                    this.showComponent = false;
                }
            }
        })
    },
    methods: {
        goToRoute() {
            if (!this.hasDeposited) {
                this.$router.push('/investments');
            } else if (this.hasDeposited && !this.hasCompletedKyc) {
                this.$router.push({name: 'Settings', params: {goToKyc: true}});
            } else if ((this.$props.type == 'blso' || this.$props.type === 'investments') && this.showComponent === false && !!Number(this.$props.stoStatus)) {
                this.$router.push({name: 'BLSO'});
            }
        }
    }
});
</script>
